import React, { Component } from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/bio.scss"
import { getTranslations, changeToLocalLinks } from "../components/helpers/helpers";
import i18Data from '../../content/intl/labels.json';


class Bio extends Component {

    componentDidMount() {
        this.windowRef = window;
        this.documentRef = document;
        this.linksUnsubs = changeToLocalLinks(this.isHtml, navigate, [ i18Data.common.url, i18Data.common.altUrl ]);
    }

    componentWillUnmount() {
        if (this.isHtml) {
            this.isHtml.removeEventListener("click", this.linksUnsubs);
        }
    }

    render() {
        const { data: { pageData }, pageContext: { lang } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const translations = getTranslations(pageData.edges, "bio");

        return (
            <Layout mainClassName="bio" noActiveMenu={true} pageContext={this.props.pageContext} translations={translations}>

                <SEO title={page.frontmatter.title} description={page.frontmatter.summary} lang={lang ? lang : "en" }/>

                <div className="bio-container initial-padding">
                    <div className="bio-content">  
                        <h1 dangerouslySetInnerHTML={{ __html: page.frontmatter.summary }}></h1>

                        <div className="is-html" ref={(t) => { this.isHtml = t }} dangerouslySetInnerHTML={{ __html: page.html }}></div>                     
                    </div>
                </div>    

            </Layout>
        )
    }
}

export default Bio


export const query = graphql`
    query BioQuery {
        pageData: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "bio" }} }) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                    }
                    id
                    html

                }
            }
        }

    }
`